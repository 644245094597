<template>
	<div id="app" class="">
		<router-view></router-view>
	</div>
</template>
<script>
	export default {
		data() {
			return {

			}
		},
		created(){
			if(localStorage.getItem('token')){
                this.$api.getInfo().then(res => {
                    if(res.code == 200){
                        localStorage.setItem('userInfo', JSON.stringify(res.data.user))
                        localStorage.setItem('permissions', JSON.stringify(res.data.permissions))
                    }
                })
			}
		},
		mounted() {},
		destroyed() {},
		methods: {

		}
	}
</script>

<style lang="scss">
	// @import url('./assets/css/uflex.css');
</style>