
const findItemById = (data, id , key, disabled = false)=> {
    let item_id = key? data[key] : data.id
    data.disabled = false
    if (item_id == id || disabled) {
        data.disabled = true
    }
    if (data.children) {
        data.children.forEach((item2,index2) => {
            data.children[index2] = findItemById(item2, id, key, data.disabled)
        });
    }
    return data;
}
export default findItemById