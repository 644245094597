export const cargoQuantity = [
    {
        id: '1',
        title: '1-100吨'
    },
    {
        id: '2',
        title: '100-200吨'
    },
    {
        id: '3',
        title: '200-300吨'
    },
    {
        id: '4',
        title: '300-400吨'
    },
    {
        id: '5',
        title: '400-500吨'
    },
    {
        id: '6',
        title: '500-600吨'
    },
    {
        id: '7',
        title: '600-700吨'
    },
    {
        id: '8',
        title: '700-800吨'
    },
    {
        id: '9',
        title: '800-900吨'
    },
    {
        id: '10',
        title: '900-1000吨'
    },
    {
        id: '11',
        title: '大于1000吨'
    },

]